
export function paymentFeeMethodName(paymnetMethodName) {
    let methodName;
    if(paymnetMethodName == 'bri_va') {
      methodName = 'BRI Virtual Account'
    } else if(paymnetMethodName == 'bca_va') {
      methodName = 'BCA Virtual Account'
    } else if(paymnetMethodName == 'gopay') {
      methodName = 'GoPay'
    } else if(paymnetMethodName == 'echannel') {
      methodName = 'Mandiri Bill'
    } else if(paymnetMethodName == 'bni_va') {
      methodName = 'BNI Virtual Account'
    } 
    return methodName;
  }

export function calculateEpayFee(payment, feePaidBy, total) {
  // Default fee is zero
  let epayFee = 0;

  // Check if the payment method is valid and not 'bankTransfer' or 'COD'
  if (payment && payment !== "bankTransfer" && payment !== "COD") {
    
    // Define transaction fees for different payment methods
    const gopayFee = (total * 2) / 100; // 2% fee for Gopay
    const creditCardFee = 4000 + (total * 11) / 100; // Fixed fee + 11% for Credit Card
    const qrisFee = (total * 0.7) / 100; // 0.7% fee for QRIS
    const virtualAccountFee = 4000; // Fixed fee for Virtual Accounts (BCA, BNI, etc.)

    // Only calculate the fee if the buyer is paying the fee
    if (feePaidBy === "buyer") {

      // Check the payment method and assign the appropriate fee
      switch (payment) {
        case "gopay":
          epayFee = gopayFee;
          break;
        case "credit_card":
          epayFee = creditCardFee;
          break;
        case "QRIS":
          epayFee = qrisFee;
          break;
        // Handle virtual account payments (BCA, BNI, eChannel, BRI)
        case "bca_va":
        case "bni_va":
        case "echannel":
        case "bri_va":
          epayFee = virtualAccountFee;
          break;
        default:
          // If payment method is unknown, default to the total amount
          epayFee = total;
          break;
      }

      // Round the fee to the nearest integer
      epayFee = Math.round(epayFee);
    }
  }

  // Return the calculated fee
  return epayFee;
}
  